.App {
  text-align: center;
}

h2 {
  text-align: center;
  float: center;
  margin-left: 220px;
  margin-right: auto;
}

.welcome_vid {
  display: block;
  margin-left: 540px;
  margin-right: auto;
  width: 30%;
  height: auto;
}

.pic {

  position: fixed;
  top: 0;
  left: 0;
  width: 140px;
  border: 3px solid;
  margin-left: auto;
}

.social_icon {
  display: flex;
  vertical-align: top;

  position: relative;
  top: 10;
  float: right;
  font-size: 30px;
  padding: 25px;
  width: 50px;
  margin-left: auto;
  color: white;
  text-decoration: none;
  transition: 0.2s;


}

.social_icon:hover {
  color: aqua
}

.social_icon {
  position: relative;
  top: 10;
  float: right;
  font-size: 30px;
  padding: 25px;
  right: auto;
  width: 50px;
  margin-left: auto;
  color: white;
  text-decoration: none;
  transition: 0.2s;


}

.social_icon:hover {
  color: aqua
}